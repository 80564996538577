<template>
    <baseContainer>
        <template v-slot:heads>
            <HeadNav/>
        </template>
        <div class="login">
            <div class="titles">
                <span>欢迎登录</span>
                <p>浙江开放大学云课堂</p>
            </div>
            <van-form @submit="onSubmit">
                <div class="field_item">
                    <p class="labels_">请输入账号</p>
                    <van-field
                            v-model="account"
                            name="account"
                            :rules="[{ required: true, message: '请填写用户名' }]"
                    >
                        <template #left-icon>
                            <i class="iconshouji iconfont"></i>
                        </template>
                    </van-field>
                </div>
                <div class="field_item">
                    <p class="labels_">请输入密码</p>
                    <van-field
                            v-model="password"
                            type="password"
                            name="password"
                            :rules="[{ required: true, message: '请填写密码' }]"
                    >
                        <template #left-icon>
                            <i class="iconmima iconfont"></i>
                        </template>
                    </van-field>
                </div>
                <div class="commit_button">
                    <van-button round block type="info" native-type="submit">
                        登录
                    </van-button>
                </div>
            </van-form>
        </div>
    </baseContainer>
</template>

<script>
import HeadNav from '../../components/headNav';
import api from '../../api/users';
import {Dialog} from 'vant';

export default {
    name: "login",
    components: {
        HeadNav
    },
    computed: {
        redirect() {
            return this.$route.query.redirect;
        }
    },
    data() {
        return {
            account: '',
            password: ''
        }
    },
    methods: {
        onSubmit(values) {
            api.goLogin(values).then(res => {
                this.$store.dispatch('users/changeUersDate', res);
                if (this.redirect) {
                    this.$router.replace(this.redirect);
                } else {
                    this.$router.replace('/personalSpace');
                }
            }).catch(res => {
                Dialog.alert({
                    message: res.data.message
                });
            });
        }
    }
}
</script>

<style scoped lang="less">
    .login {
        padding: 40px 40px 40px;
        background: #FFFFFF;
        .titles {
            span {
                font-size: 34px;
                font-weight: 400;
                color: #8A94A8;
            }
            p {
                font-size: 40px;
                font-weight: 600;
                color: #237DF9;
                line-height: 56px;
            }
        }
        .van-form {
            margin-top: 96px;
            .van-field {
                padding-left: 0;
                padding-top: 50px;
                border-bottom: 2px solid #D9D9D9;
            }
            .van-cell:after {
                border-bottom: none;
            }
            /deep/ .van-field__control {
                margin-left: 36px;
                font-size: 32px;
                color: #333333;
            }
            /deep/ .van-field__error-message {
                position: absolute;
                left: -40px;
                top: -50px;
                z-index: 200;
                background-color: #fff;
                width: 100%;
            }
            .commit_button {
                margin-top: 54px;
            }
        }
        .field_item {
            margin-top: 40px;
            position: relative;
            .labels_ {
                position: absolute;
                font-size: 24px;
                color: #D9D9D9;
                line-height: 48px;
                z-index: 100;
            }
        }
        .iconfont {
            color: #333333;
            font-size: 34px;
        }
    }
</style>